.row {
   display: flex;
   justify-content: center;
   /* align-items: center; */
   /* flex-direction: column; */
   flex-wrap: wrap;
   padding: 20px 10px;
}

.card {
   border-radius: 0 !important;
   box-shadow: none !important;
   padding: 0 !important;
   margin: 0 !important;
   width: 100% !important;
   transition: all 0.3s ease-out;
   cursor: pointer;
}
.card-wrapper {
   padding: 10px;
   /* margin: 0 20px 20px; */
}
.card-width {
   border-radius: 8px;
   box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.15);
   border: 1px solid #eeeeee;
   background-color: #ffffff;
   padding: 0 10px 10px;
   transition: all 0.5s ease-in-out;
}
.card-width:hover {
   box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.5);
}
.card-header {
   display: flex;
   justify-content: space-between;
   font-weight: 400;
   font-size: 16px;
   align-items: center;
   border-bottom: 1px solid #eeeeee;
   /* padding: 10px 0; */
   margin-bottom: 10px;
   height: 50px;
}

.icon-wraper {
   border: 1px solid black;
   border-radius: 20px;
   height: 23px;
   width: 25px;
   display: flex;
   justify-content: center;
   padding-top: 1px;
   padding-bottom: 00.5px;
}

.card-header h5 {
   font-family: "Helvetica", "Arial", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 18px;
   color: #333333;
   margin: 0;
}

.widget-card {
   display: flex;
   justify-content: space-between;
}

.widget-card h5 {
   font-family: "Helvetica", "Arial", sans-serif;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 156.02%;
   color: rgb(53 53 53 / 80%);
   margin: 10px 0;
}

.widget-card .value {
   font-family: "Helvetica", "Arial", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 22px;
   line-height: 156.02%;
   color: rgb(56, 149, 254);
   margin: 0px 5px 0px 5px;
   /* margin-left: auto; */
}

/* modal */

.modal-container {
   max-width: 550px;
   width: 100%;
   min-height: 140vh;
   /* border: 1px solid #cecece; */
   border-radius: 12px;
   /* overflow-x: scroll; */
   position: absolute;
   z-index: 10;
   top: -2000px;
   /* left: calc(100vw - 50% - 215px); */
   /* will-change: top; */
   /* background-color: #fff; */
   background-color: #ffffff;;
   /* height: 90%; */
   /* bottom: -1000px; */
   /* transition-duration: 1s; */
   transition: all 0.5s ease-in-out;
}
.modal-container.active {
   top:0;
}
/* new css */

.head-info {
   display: flex;
   gap: 10px;
   align-items: center;
}
.area-box {
  margin-right: 20px;
}
.widthHalf {
   width: 50% !important;
}
.widthFull {
   width: 100% !important;
}
.status {
   font-family: "Helvetica", "Arial", sans-serif;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 23px;
   margin: 0;
}
.busy {
   /* color: #fe5f49; */
   color: #fff;
   background: #ff4e4e;
   padding: 3px 7px;
   border-radius: 15px;
}
.notBusy {
   /* color: #1dba83; */
   color: #fff;
   background: rgb(11, 187, 11);
   padding: 3px 7px;
   border-radius: 15px;
   word-break:keep-all;
}

.live{
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* flex-wrap: wrap; */
   padding: 10px;
   /* flex-direction: column; */
}

.live1{
   display: flex;
   justify-content: flex-start;
   align-items: center;
   flex-wrap: wrap;
   padding: 10px;
   /* width: 80%; */
   /* flex-direction: column; */
}

.not-live{
   color: #999;
   padding: 3px 7px;
   display: inline-block;
   margin: 5px 5px 5px 20px;
}

.main-loading{
   padding: 2px;
   /* margin: 5px 0 5px 0 */
}

.footer {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
   padding-bottom: 10px;
}

.copyright-text {
   display: flex;
   align-items: center;
   font-style: italic;
   font-weight: 400;
   font-size: 17px;
   color: white;
   letter-spacing: 0.6;
}

@media screen and (max-width: 500px) {
   .widget-container {
      height: 700px !important;
   }
   .modal-container {
      /* top: unset !important;
      bottom: 0 !important; */
      border: none;
      min-height: 140vh;
   }
}
@media screen and (max-width: 350px) {
   .card-header h5{
      font-size: 13px;
      line-height: 110%;
   }
   .modal-container.active {
      min-height: 155vh;
   }
   
}
