#root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.main {
  max-width: 550px;
  overflow-x: hidden;
  border-radius: 12px;
  background-color: #fafcff;
  border: 1px solid #cecece;
}
.header-content {
  display: flex;
  flex-direction: row;
  height: 50px;
  justify-content: end;
  padding-top: 10px;
  padding-right: 15px;
}

.header-image {
  background-image: url("../public/rcgimages/building.jpg");
  width: 100%;
  height: 250px;
}

.live-icon {
  display: flex;
  align-items: center;
  height: 30px;
  width: 75px;
  background-color: rgba(96, 176, 87, 0.3);
  border-radius: 50px;
  justify-content: center;
}

.live-icon div {
  height: 12px;
  width: 12px;
  background-color: #60b057;
  border-radius: 50%;
  margin-right: 5px;
}
.live-icon h4 {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  /* font-weight: bold; */
  color: #60b057;
}

.header-content img {
  margin-right: 7px;
}
