:root {
  --shadowSize: 20px;
  --shadowColor: #f00;
}
.graph-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  position: relative;
}

.left-labels {
  width:15px;
  /* padding-top: 10px; */
  padding-right: 5px ;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.left-label {
  line-height: 0px;
}

.left-label-line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.left-label-horizontal-line {
  width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  /* border-top: 0.5px #ccc; */
  padding: 0.3px;
  margin-top: 1px;
  background-color: rgb(216, 213, 213);
}
/* .left-label-horizontal-line {
  width: calc(100% - 40px);
  position: absolute;
  left: 20px;
  border-top: 0.5px #ccc;
} */

.main-graph-wrapper {
  width: 100%;
  height: 100%;
}

.main-graph {
  width: calc(100% - 60px);
  height: 100%;
  border: 0px solid #ddd;
  border-bottom-width: 2px;
  border-left-width: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0px 30px;
  position: relative;
}

.graph-item {
  width: 3%;
  /* background-color: #39499e; */
  position: relative;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  /* height: 100%; */
  /* z-index: 1; */
  cursor: pointer;
}

.graph-item-historic {
  width: 3%;
  height: 0px;
  background-color: #dadce0;
  position: absolute;
  /* left: 0;
  bottom: 0; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 0;
}

.graph-item-tooltip {
  width: 100%;
  height: 100%;
}

.graph-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5px;
  padding: 0px 30px;
}

.graph-label-item {
  color: #212b5d;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 3px;
}

.graph-label-item-tick {
  width: 2px;
  height: 10px;
  position: absolute;
  top: -5px;
  left: 10px;
  background-color: #eaeaea;
}

.tooltip-line {
  width: 1px;
  height: 100%;
  border-left: 2px dashed #d8d8d8;
  position: absolute;
  left: 50%;
  bottom: 100%;
}

.graph-tooltip {
  /* width: 190px; */
  width: fit-content;
  padding: 0 7px;
  white-space: nowrap;
  height: 28px;
  position: absolute;
  top: -80px;
  left: -112px;
  background-color: #fff;
  box-shadow: 0px 1px 10px #ccc;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #3ab34a;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 16px;
  letter-spacing: 1.39px;
  z-index: 20;
}
.graph-tooltip-unclicked {
  height: 35px;
  display: flex;
  margin-top: -25px;
  justify-content: center;
  align-items: center;
  color: #3ab34a;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 20px;
  letter-spacing: 1.39px;
  z-index: 10;
}
.bar-chart-labels {
  margin-top: -20px;
}

/* .graph-tooltip::after {
  content: "";
  position: absolute;
  left: attr(data-contnet);
  bottom: -20px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  box-shadow: 0 0 var(--shadowSize) #ccc;
  transform: translate(50%, -50%) rotate(135deg);
  clip-path: polygon(
    calc(var(--shadowSize) * -1) calc(var(--shadowSize) * -1),
    calc(100% + var(--shadowSize)) calc(var(--shadowSize) * -1),
    calc(100% + var(--shadowSize)) calc(100% + var(--shadowSize))
  );
} */
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* IE */
@-ms-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Opera and prob css3 final iteration */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

.graph-tooltip > img {
  margin-right: 5px;
  -moz-animation: blink normal 2s infinite ease-in-out; /* Firefox */
  -webkit-animation: blink normal 2s infinite ease-in-out; /* Webkit */
  -ms-animation: blink normal 2s infinite ease-in-out; /* IE */
  animation: blink normal 2s infinite ease-in-out; /* Opera and prob css3 final iteration */
}

.graph-tooltip > svg {
  margin: 0px 3px;
  height: 20px;
  width: 7px;
}

.graph-tooltip-text {
  margin-right: 10px;
  font-size: 16px;
}

.graph-loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 800px) {
  .graph-tooltip {
    font-size: 12px;
  }
  .left-label-line {
    font-size: 14px;
  }
  .graph-tooltip-unclicked {
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .graph-container {
    height: 200px;
  }
  .graph-tooltip-unclicked {
    font-size: 14px;
  }

  .graph-label {
    font-size: 12px;
  }

  .graph-item {
    width: 10px;
  }

  .graph-item-historic {
    width: 9px;
    margin-left: 1px;
  }

  .graph-tooltip {
    width: 180px;
    font-size: 11px;
    height: 25px;
    top: -40px;
    left: -60px;
  }

  .graph-tooltip > img {
    width: 15px;
    height: 15px;
  }

  .graph-tooltip > svg {
    width: 10px;
    height: 10px;
    margin: 0px;
  }

  .graph-label-item {
    font-size: 12px;
  }

  .graph-label-item-tick {
    left: 2px;
  }
  .left-label-line {
    font-size: 9px;
  }
}

@media screen and (max-width: 400px) {
  .graph-item {
    width: 8px;
  }
}