.day-link {
  color: #329acc;
  font-size: 15px;
  letter-spacing: 3px;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.day-link:hover {
  /* text-decoration: underline; */
  cursor: pointer;
}
.day-active {
  font-weight: bold;
  background-color: #329acc;
  border-radius: 12px;
  color: white;
}

@media screen and (max-width: 500px) {
  .day-link {
    font-size: 13px;
    padding: 8px 6px;
    letter-spacing: 0px;
  }
}
