.widget-container {
  background-repeat: no-repeat;
  background-size: cover;
}

.widget-overlay {
  height: 100%;
  width: 100%;
  /* background-color: rgba(255, 255, 255, 0.9); */
}

.widget-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #9c9b9b;
  padding: 10px 0;
  /* margin-top: 10px; */
  overflow-x: auto;
}
.widget-mobile {
  display: flex;
  width: 100%;
  height: 80%;
}
.widget-mobile-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* background-color: #fff; */
  border-bottom: 1px solid #d8d8d8;
}

.widget-body {
  padding: 0;
}

.widget-body-top {
  display: flex;
  flex-direction: row;
  margin-bottom: 70px;
}

.widget-body-top-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.widget-body-top-title {
  font-size: 30px;
  font-weight: bold;
  color: #3299cb;
  font-family: "Helvetica", "Arial", sans-serif;
}

.widget-body-top-right-title {
  display: flex;
  align-items: center;
  height: 65px;
}

.widget-body-top-zones {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.separator {
  margin: 0px 5px;
  font-weight: bold;
  font-size: 20px;
}

.widget-body-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  font-size: 18px;
  color: #212b5d;
  font-family: "Helvetica", "Arial", sans-serif;
}
.widget-body-grouped-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.widget-mobile-header-image {
  height: 130px;
  width: 100%;
  /* width:100vw; */
  opacity: 0.7;
}

.widget-body-top-left {
  margin-left: 20px;
  margin-top: 20px;
}
@media screen and (max-width: 500px) {
  .widget-container {
    height: 700px;
  }
  .widget-body {
    padding: 0;
    /* margin-left: 25px; */
  }
  .widget-mobile-header-image {
    height: 130px;
    opacity: 0.7;
    /* width: 95%; */
  }

  .close-icon {
    margin-right: 20px;
  }
  .widget-body-top-right {
    /* margin-left: 29px; */
  }

  .widget-header {
    /* width: 35px; */
    /* margin-left: 22px; */
  }

  .widget-body-top {
    margin-bottom: 70px;
    margin-top: 5px;
  }

  .widget-body-top-left > svg {
    width: 50px;
    height: 50px;
  }

  .widget-body-top-title {
    font-size: 16px;
  }

  .separator {
    margin: 0px 5px;
    font-weight: bold;
    font-size: 12px;
  }

  .widget-body-bottom {
    font-size: 9px;
    margin-top: 25px;
    margin-bottom: 10px;
  }
}

/* cartd */

.card {
  border-radius: 5px;
  box-shadow: 7px 7px 13px 0px rgba(50, 50, 50, 0.22);
  padding: 8px;
  margin: 20px;
  width: 275px;
  transition: all 0.3s ease-out;
}
