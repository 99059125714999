.moii-widget{
  margin: 10px 20px;
}

@media screen and (max-width: 500px) {
  .moii-widget{
    margin: 10px 15px;
  }
}
@media screen and (max-width: 350px) {
  .moii-widget{
    margin: 6px;
  }
}