.zone-link {
  font-size: 16px;
  font-family: "Helvetica", "Arial", sans-serif;
  padding: 8px 25px;
  border-radius: 4px;
  background-color: white;
  color: #3299cb;
  border: 1px solid #3299cb;
  border-radius: 20px;
  font-weight: 100;
}

.zone-removed {
  padding: 10px 25px;
}

.zone-link:hover {
  cursor: pointer;
}

.zone-active {
  font-weight: bold;
  background-color: #3299cb;
  color: white;
  border: 0px;
}

@media screen and (max-width: 500px) {
  .zone-link {
    font-size: 10px;
    padding: 4px 10px;
  }
  .zone-removed {
    display: none;
  }
}
